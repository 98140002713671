import { MainScene } from '../core/Main.scene';


export class CustomMainScene extends MainScene {
  cameraStartPos = {
    x: 960,
    y: 4203
  };

  setCamera() {
    this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
    this.cameras.main.zoomTo(0.2, 0.1);
  }

}

