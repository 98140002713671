<!-- MISSIONS -->
<ng-container *ngIf="data.player as player">
  <!-- QUIZ -->
  <button
    *ngIf="FEATURE_FLAG.HUD.QUIZ"
    class="wipe"
    (click)="openQaList()"
    [matTooltip]="'hud-menu-custom.qa.tooltip' | myTranslate"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <ow-object-svg [svgObject]="OW_ASSETS_SVG['/assets/ui/quiz.svg']"></ow-object-svg>
  </button>
</ng-container>

<ng-template #notificationTpl>
  <i class="fas fa-exclamation icon notification"></i>
</ng-template>
