<div class="card-container">
  <div
    *ngIf="cardTask; else emptyCardTpl"
    class="card"
    [class.empty-choose]="!cardTask.selected && cardTask.other_tasks.length"
    [class.parametrize]="!cardTask.selected && cardTask.other_tasks.length === 0"
    [class.choose]="cardTask.other_tasks.length"
    (click)="actionCard()"
  >
    <ng-container *ngIf="cardTask.selected as selectedCard">
      <!-- TITLE -->
      <div class="title">
        {{ selectedCard.task_definition.name }}
      </div>

      <ng-container *ngIf="selectedCard.performance !== null && selectedCard.target; else noBusinessResultTpl">
        <div class="card-content">
          <!-- PRIZES -->
          <div class="prizes">

            <!-- SCOPE PRIZES 100% -->
            <div class="prize" *ngFor="let currency of selectedCard.scopesPrizes">
              <m-ui-currency
                [item]="{currency_id: currency.currency_id, amount: currency['amount'] * selectedCard.multiplier | floor}"
                [stockView]="STOCK_VIEW.H"
                [lack]="false"
              ></m-ui-currency>
            </div>

            <!-- BASE CURRENCY PRIZES -->
            <div class="prize" *ngFor="let currency of selectedCard.base_currency_prizes">
              <m-ui-currency
                [item]="{currency_id: currency.currency_id, amount: currency['amount'] * selectedCard.multiplier | floor}"
                [stockView]="STOCK_VIEW.H"
                [lack]="false"
              ></m-ui-currency>
            </div>
          </div>

          <!-- PROGRESS BLOCK -->
          <div class="progress-block" *ngIf="selectedCard.performance !== null">
            <!-- PROGRESSBAR -->
            <div class="progress-container">
              <div
                class="fill"
                [ngClass]="selectedCard.taskClasses"
                [style.width]="selectedCard.realization * 100 + '%'"
              ></div>
            </div>

            <!-- VALUE -->
            <span class="value">
              {{selectedCard.realization | floor:'float' | percent}}
            </span>
          </div>
        </div>
      </ng-container>
      <ng-template #noBusinessResultTpl>
        <div class="parametrize">
          {{ 'business.card-task.info-no-business-result' | myTranslate }}
        </div>
      </ng-template>
    </ng-container>
  </div>
  <ng-template #emptyCardTpl>
  </ng-template>
</div>
