<div class="ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR ?-->
  <!-- <div class="back-bar" *ngIf="dialogData.backButton">-->
  <!-- <button class="square normal back" [mat-dialog-close]></button>-->
  <!-- </div>-->

  <!-- TOP BAR LONG -->
  <div class="top-bar long" *ngIf="data.player">
    <div class="title">
      {{ data.player.first_name }} {{ data.player.last_name }}
    </div>
  </div>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="notEmptyCardTasks.length > 1">
    <m-ui-navigation-center
      (prevClicked)="prev()"
      (nextClicked)="next()"
    ></m-ui-navigation-center>
  </ng-container>

  <ng-container *ngIf="task">
    <!-- TITLE -->
    <div class="title">
      {{ task.task_definition.name }}
    </div>

    <!-- DESCRIPTION -->
    <div
      class="description"
      [innerHtml]="task.task_definition.description"
      owEllipsis
    >
    </div>

    <!-- PROGRESSBAR -->
    <ng-container *ngIf="task.performance !== null">
      <div class="progress-title">
        {{ 'business.card-task-details.progress-title' | myTranslate }}
      </div>

      <div class="progress-bar-container">
        <div class="progress-container">
          <div
            class="fill"
            [ngClass]="task.taskClasses"
            [style.width]="task.realization * 100 + '%'"
          ></div>
        </div>
        <div
          class="value-progressbar"
          [style.left]="(task.realization >= 1) ? '100%' : (task.realization * 100 + '%')"
        >
          {{task.realization | percent:'0.2-2'}}
        </div>
      </div>
    </ng-container>

    <!-- PRICES -->
    <div class="prices">
      <div>
        <div
          class="price"
          *ngFor="let scope of task.scopes; let index = index"
        >
          <!-- PRICE ICON -->
          <div
            class="price-icon"
            [ngClass]="'price-' + (index + 1)"
          ></div>

          <div class="value-container">
              <span class="value">
                <ng-container *ngIf="scope.currency_prizes.length">
                   {{ scope.currency_prizes[0]['amount'] * task.multiplier | floor | NumberWithSpaces }}
                </ng-container>
              </span>
          </div>
          <div class="progress">{{ scope.target | percent }}</div>
        </div>
      </div>

      <div>
        <div class="price price-4" *ngIf="task.base_currency_prizes.length">
          <div class="value-container">
              <span class="value">
                {{ task.base_currency_prizes[0]['amount'] * task.multiplier | floor | NumberWithSpaces }}
              </span>
            <span class="value-description">{{ 'business.card-task-details.base-prize' | myTranslate }}</span>
          </div>
          <div class="progress">{{ 'business.card-task-details.base-progress' | myTranslate }}</div>
        </div>

        <div
          class="price price-5"
          *ngIf="task.base_currency_prizes.length"
          (click)="openInfo()"
        >
          <div class="value-container">
            <i class="far fa-info-circle info-icon"></i>
            <span class="value">
                <ng-template
                  let-value
                  [ngTemplateOutletContext]="{ $implicit: task.base_currency_prizes[0]['amount'] * (task.forecast_multiplier || 0) * task.multiplier }"
                  [ngTemplateOutlet]="t"
                  #t
                >
                  {{ (value > 0 ? value : 0) | floor |  NumberWithSpaces }}
                </ng-template>
              </span>
            <span class="value-description">{{ 'business.card-task-details.prognosis' | myTranslate }}</span>
          </div>
          <div class="progress">
            {{ (task.realization || 0) | percent:'0.2-2'}}
          </div>
        </div>
      </div>
    </div>

    <!-- TABLE -->
    <ng-container *ngIf="task.performance !== null && task.target; else noBusinessResultTpl">
      <table>
        <tbody>
        <tr>
          <td>{{ 'business.card-task-details.target' | myTranslate }}</td>
          <td>
            <ng-container
              [ngTemplateOutlet]="valueTypeTpl"
              [ngTemplateOutletContext]="{value: task.target}"
            ></ng-container>
          </td>
          <td>{{ 'business.card-task-details.progress-percent' | myTranslate }}</td>
          <td>{{task.realization | percent:'0.2-2'}}</td>
        </tr>
        <tr>
          <td>{{ 'business.card-task-details.performance' | myTranslate }}</td>
          <td>
            <ng-container
              [ngTemplateOutlet]="valueTypeTpl"
              [ngTemplateOutletContext]="{value: task.performance}"
            ></ng-container>
          </td>
          <ng-container *ngIf="task.min_valid !== null">
            <td>{{ 'business.card-task-details.minimum-requirements' | myTranslate }}</td>
            <td>{{task.min_valid ? 'Tak' : 'Nie'}}</td>
          </ng-container>
        </tr>
        </tbody>
      </table>

      <span class="min-info" *ngIf="task.min_valid !== null">
        {{ 'business.card-task-details.info' | myTranslate }}
      </span>
    </ng-container>
    <!-- NO BUSINESS RESULT TPL -->
    <ng-template #noBusinessResultTpl>
      <p class="description no-business"
         [innerHTML]="'business.card-task-details.info-no-business-result' | myTranslate"
      ></p>
    </ng-template>

    <!-- CHOOSE TASKS BUTTONS-->
    <!-- W Expert city osobny component do podmieniania przycisków-->
    <!-- <choose-tasks-buttons-->
    <!-- *ngIf="!data.hideChooseTasksButtons"-->
    <!-- [task]="task"-->
    <!-- [cardTask]="cardTask"-->
    <!-- [showOtherClose]="data.showOtherClose"-->
    <!-- (cancelTaskEvent)="cancelEvent($event)"-->
    <!-- ></choose-tasks-buttons>-->
    <ng-container *ngIf="!data.hideChooseTasksButtons">
      <div class="bottom-bar" *ngIf="cardTask.other_tasks.length">
        <div class="bar-button base">
          <button class="base secondary">
            Wybierz
          </button>
        </div>
        <div class="bar-button base">
          <button class="base primary">
            Zobacz inne
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #valueTypeTpl let-value="value">
  <ng-container [ngSwitch]="task.task_definition.value_type">
    <!-- INT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.INT">
      {{ value }}{{ task.task_definition.string_format }}
    </ng-container>
    <!-- FLOAT PERCENTAGE -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT_PERCENTAGE">
      {{ value | percent:'1.2-2' }}
    </ng-container>
    <!-- FLOAT -->
    <ng-container *ngSwitchCase="VALUE_TYPE.FLOAT">
      {{ value | floor:'float' | number:'1.2-2' }}{{ task.task_definition.string_format }}
    </ng-container>
  </ng-container>
</ng-template>
