import { Component } from '@angular/core';
import { AbstractHudMenuComponent } from '../../../../abstract/core/abstract-hud-menu.component';
import { OW_ASSETS_SVG } from '../../../../../../../../../assets-svg.const';
import { FEATURE_FLAG } from '../../../../../../../../core/config/core/feature-flags.config';
import { QUIZ_LOCATION } from '../../../../../qa/consts/core/qa-location.const';
import { EVENT_DIALOGS_NAMES_QA } from '../../../../../qa/consts/core/event-dialogs/event-names.const';

@Component({
  selector: 'app-custom-hud-menu',
  templateUrl: './hud-menu.component.html',
  styleUrls: ['./hud-menu.component.scss']
})
export class HudMenuComponent extends AbstractHudMenuComponent {
  OW_ASSETS_SVG = OW_ASSETS_SVG;
  FEATURE_FLAG = FEATURE_FLAG;

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          location: QUIZ_LOCATION.MENU_GUI
        }
      }
    })
  }
}
