import { CARD_TEMPLATE } from '../../consts/custom/card-template.const';
import { Task } from '../../interfaces/custom/task.interface';

export function setTaskClasses(task: Task): string[] {
  const classes: string[] = [];

  if (task.performance !== null) {
    // MIN VALID
    switch (task.min_valid) {
      case true:
        classes.push('have-min-valid-true');
        break;

      case false:
        classes.push('have-min-valid-false');
        break;

      case null:
        classes.push('no-min-valid');
        break;
    }

    // REALIZATION
    if (task.realization >= 1) {
      classes.push('realization-up-100');
    } else {
      classes.push('realization-down-100');
    }

    // CARD TEMPLATE
    switch (task.card_template) {
      case CARD_TEMPLATE.INDIVIDUAL:
        classes.push('card-template-1');
        break;

      case CARD_TEMPLATE.SHOP:
        classes.push('card-template-2');
        break;
    }
  }

  return classes;
}
