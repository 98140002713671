import { HudMenuComponent } from '../../../../custom/basic/components/hud-menu/hud-menu.component';
import { BusinessCardsComponent } from '../../../../../business/basic/custom/components/business-cards/business-cards.component';

export const CUSTOM_DYNAMIC_COMPONENTS = {
  BUSINESS: {
    component: BusinessCardsComponent,
    viewContainerRef: 'dynamicsComponentTpl',
  },
  HUD_MENU_CUSTOM: {
    component: HudMenuComponent,
    viewContainerRef: 'hudMenuTpl',
    data: (context) => {
      return {
        player: context['player'],
      };
    }
  }
};
